<template>
  <v-navigation-drawer app clipped expand-on-hover class="side-menu" permanent touchless width="300">
    <v-layout class="primary-text" column fill-height>
      <!-- account pages -->
      <v-list v-if="thereIsAnAccount" nav>
        <v-list-item exact to="/accounts">
          <v-list-item-icon>
            <v-icon>mdi-chevron-left</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="body-2">Contas</v-list-item-content>
        </v-list-item>

        <v-list-item :to="`/accounts/${accountId}/edit/geral`">
          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="body-2">Configurações</v-list-item-content>
        </v-list-item>

        <v-list-group :value="false" prepend-icon="mdi-package-variant">
          <template v-slot:activator>
            <v-list-item-title class="body-2">Canais</v-list-item-title>
          </template>

          <v-list-item :to="`/accounts/${accountId}/campaigns`">
            <v-list-item-icon>
              <v-icon>flash_on</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="body-2">Campanhas</v-list-item-content>
          </v-list-item>

          <v-list-item :to="`/accounts/${accountId}/vouchers`">
            <v-list-item-icon>
              <v-icon>mdi-ticket-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="body-2">Vouchers</v-list-item-content>
          </v-list-item>

          <v-list-item :to="`/accounts/${accountId}/clientes`">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="body-2">Clientes Hotsite</v-list-item-content>
          </v-list-item>

          <v-list-item :to="`/accounts/${accountId}/app-config`">
            <v-list-item-icon>
              <v-icon>mdi-cellphone-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="body-2">Configurar App</v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="false" prepend-icon="mdi-chart-bar">
          <template v-slot:activator>
            <v-list-item-title class="body-2">Logs</v-list-item-title>
          </template>

          <v-list-item :to="`/accounts/${accountId}/reports`">
            <v-list-item-icon>
              <v-icon>mdi-book-alert</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="body-2">GE Reports</v-list-item-content>
          </v-list-item>

          <v-list-item :to="`/accounts/${accountId}/logs`">
            <v-list-item-icon>
              <v-icon>mdi-message-reply-text</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="body-2">Logs</v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="false" prepend-icon="mdi-connection">
          <template v-slot:activator>
            <v-list-item-title class="body-2">Conexões</v-list-item-title>
          </template>

          <v-list-item :to="`/accounts/${accountId}/friday`">
            <v-list-item-icon>
              <v-icon>mdi-laptop</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="body-2">Friday</v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="false" prepend-icon="mdi-whatsapp">
          <template v-slot:activator>
            <v-list-item-title class="body-2">Whatsapp</v-list-item-title>
          </template>

          <v-list-item :to="`/accounts/${accountId}/whatsapp`">
            <v-list-item-icon>
              <v-icon>mdi-contacts</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="body-2">Contatos</v-list-item-content>
          </v-list-item>
          <v-list-item :to="`/accounts/${accountId}/instancias-whatsapp`">
            <v-list-item-icon>
              <v-icon>mdi-access-point-check</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="body-2">Instâncias</v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <!-- general pages -->
      <v-list v-else nav>
        <v-list-item to="/home">
          <v-list-item-icon>
            <v-icon>home</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="body-2">Home</v-list-item-content>
        </v-list-item>

        <v-list-group :value="false" prepend-icon="mdi-cog">
          <template v-slot:activator>
            <v-list-item-title>Gerenciamento</v-list-item-title>
          </template>
          <v-list-item to="/accounts">
            <v-list-item-icon>
              <v-icon>mdi-account-box-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="body-2">Contas</v-list-item-content>
          </v-list-item>

          <v-list-item to="/erps">
            <v-list-item-icon>
              <v-icon>mdi-briefcase</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="body-2">ERPs</v-list-item-content>
          </v-list-item>

          <v-list-item to="/retailers">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="body-2">Varejistas</v-list-item-content>
          </v-list-item>

          <v-list-item to="/franchises">
            <v-list-item-icon>
              <v-icon>mdi-storefront</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="body-2">Redes</v-list-item-content>
          </v-list-item>

          <v-list-item to="/integra-client">
            <v-list-item-icon>
              <v-icon>mdi-transit-connection-horizontal</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="body-2">Integra Cliente</v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="false" prepend-icon="mdi-merge">
          <template v-slot:activator>
            <v-list-item-title>Implantação</v-list-item-title>
          </template>

          <v-list-item to="/implantacao">
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="body-2">Dashboard</v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="false" prepend-icon="mdi-tools">
          <template v-slot:activator>
            <v-list-item-title>Utilidades</v-list-item-title>
          </template>

          <v-list-item to="/feature-branch">
            <v-list-item-icon>
              <v-icon>mdi-gitlab</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="body-2">Feature-Branch</v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="false" prepend-icon="mdi-account-cog">
          <template v-slot:activator>
            <v-list-item-title>Usuários</v-list-item-title>
          </template>

          <v-list-item to="/usuarios">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="body-2">Gerenciar Usuários</v-list-item-content>
          </v-list-item>

          <v-list-item to="/sessions">
            <v-list-item-icon>
              <v-icon>mdi-head-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="body-2">Gerenciar Acessos</v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="false" prepend-icon="mdi-cellphone">
          <template v-slot:activator>
            <v-list-item-title>Aplicativo</v-list-item-title>
          </template>
          <v-list-item to="/app">
            <v-list-item-icon>
              <v-icon>mdi-cellphone-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="body-2">Publicação de Apps</v-list-item-content>
          </v-list-item>
          <v-list-item to="/app-logs">
            <v-list-item-icon>
              <v-icon>mdi-atom-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="body-2">Logs e deploy</v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group :value="false" prepend-icon="mdi-handshake" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Indústria</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item to="/mercapromo">
            <v-list-item-content>
              <v-list-item-title>Mercapromo</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/mercatrade">
            <v-list-item-content>
              <v-list-item-title>Mercatrade</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/calendario-mercatrade">
            <v-list-item-content>
              <v-list-item-title>Calendário Mercatrade</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/criterios-segmento">
            <v-list-item-content>
              <v-list-item-title>Critérios de Segmento</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/produtos-segmento">
            <v-list-item-content>
              <v-list-item-title>Produtos de Segmento</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <side-menu-bi />
      </v-list>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
export default {
  components: {
    SideMenuBi: () => import('./SideMenuBI')
  },
  data: () => ({
    accountId: ''
  }),
  computed: {
    thereIsAnAccount() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.accountId = this.$route.params.id
      if (this.accountId && this.$route.path.includes('/accounts')) {
        return true
      }
      return false
    }
  }
}
</script>
<style lang="scss" scoped>
.side-menu {
  z-index: 10;
}
</style>
